import React from 'react'
import { Heading, P, TextLink, UL } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { merge } from 'lodash'
import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'
const LayoutCopy = ({ content, $mdOptions }) => {
  const mergedOptions = $mdOptions
    ? merge({}, defaultOptions, $mdOptions)
    : defaultOptions
  return <Markdown options={mergedOptions}>{content}</Markdown>
}

const CustomUL = styled((props) => {
  const { children, ...rest } = props
  return (
    <Box mb={2}>
      <UL {...rest} variant="body1">
        {children}
      </UL>
    </Box>
  )
})`
  & > li > p {
    display: inline;
  }
`

const StyledHeading = styled(Heading)`
  word-break: break-word;

  &:first-child {
    margin-top: 0;
  }

  &:is(h4, h5, h6) {
    margin-top: 0.7333em;
    line-height: 0.9;
    margin-top: 0.7333em;
  }
`

const StyledP = styled(P)`
  &:first-child {
    margin-top: 0;
  }
`
const NoOrphanHeading = React.forwardRef(function NoOrphanHeading({ children, ...props }, ref) {
  // Function to process the text and ensure non-breaking space between the last two words
  const processText = (text) => {
    const words = text.split(' ')

    // If there are more than one word, replace the last space with a non-breaking space
    if (words.length > 1) {
      const lastTwoWords = words.slice(-2).join('\u00A0') // \u00A0 is a non-breaking space
      const restOfText = words.slice(0, -2).join(' ')
      return `${restOfText} ${lastTwoWords}`
    }

    return text // Return as is if only one word
  }

  // Handle children being an array of elements or strings
  if (Array.isArray(children)) {
    // Concatenate all children that are strings into a single string, ignoring React elements
    children = children
      .map((child) => (typeof child === 'string' ? child : ''))
      .join(' ')
      .trim()
  }

  // Process the text if children is a string
  if (typeof children === 'string') {
    children = processText(children)
  }

  // Return the Heading component with forwarded ref and processed children
  return (
    <StyledHeading ref={ref} {...props}>
      {children}
    </StyledHeading>
  )
})

const defaultOptions = {
  forceBlock: true,
  overrides: {
    p: {
      component: StyledP,
    },
    h1: {
      component: NoOrphanHeading,
      props: {
        variant: 'h2',
      },
    },
    h2: {
      component: NoOrphanHeading,
      props: {
        variant: 'h3',
      },
    },
    h3: {
      component: NoOrphanHeading,
      props: {
        variant: 'h4',
      },
    },
    h4: {
      component: NoOrphanHeading,
      props: {
        variant: 'h5',
      },
    },
    h5: {
      component: NoOrphanHeading,
      props: {
        variant: 'h6',
      },
    },
    h6: {
      component: NoOrphanHeading,
      props: {
        variant: 'h6',
        style: {
          fontSize: '0.8305rem',
        },
      },
    },
    ul: {
      component: CustomUL,
    },
    a: {
      component: TextLink,
    },
  },
}

export default LayoutCopy
